import Link from 'next/link'
import styled from 'styled-components'

import Box from '@components/atoms/Box'
import Button from '@components/atoms/Button'
import Col from '@components/atoms/Grid/Col'
import HorizontalRule from '@components/atoms/HorizontalRule'
import IconClubCard from '@components/atoms/Icons/IconClubCard'
import IconHeadset from '@components/atoms/Icons/IconHeadset'
import LinkButton from '@components/atoms/LinkButton'
import ListItem from '@components/atoms/ListItem'
import LogoSunpoint from '@components/atoms/Logos/LogoSunpoint'
import LogoWellmaxx from '@components/atoms/Logos/LogoWellmaxx'
import Text from '@components/atoms/Text'
import { TextParagraphStyles } from '@components/atoms/Text/Text.styles'

import { shouldUseWhiteText } from '@helper/color'
import { userSelectNone } from '@helper/mixins'

export const Root = styled.footer`
  position: relative;
  z-index: 40;
  margin: 0;
  padding: 4rem 0 0 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledLogoWellmaxx = styled(LogoWellmaxx)`
  margin-bottom: ${(props) => props.theme.margin.default};
`

export const Section = styled.section`
  color: ${(props) => props.theme.palette.default.black};
  background-color: ${(props) => props.theme.palette.default.primary};
  &:last-child {
    padding-bottom: 1.875rem;
  }
`
export const FirstSection = styled(Section)`
  padding-bottom: ${(props) => props.theme.margin.default};
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: ${(props) => props.theme.palette.default.white};
    z-index: 1;
  }
  &:after {
    content: ' ';
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 80%;
    background: ${(props) => props.theme.palette.default.yellow};
    z-index: 1;
  }
`

export const SameHeightCol = styled(Col)`
  display: flex;
  flex: 1;
  align-items: stretch;
  @media (min-width: ${(props) => props.theme.device.desktop}) {
    margin-bottom: 0;
  }
`

export const StyledBox = styled(Box)`
  height: auto;
  margin-bottom: ${(props) => props.theme.margin.default};
  position: relative;
  z-index: 10;
  @media (min-width: ${(props) => props.theme.device.desktop}) {
    margin-bottom: 0;
  }
`

export const LastText = styled(Text)`
  margin-bottom: 0;
`

export const StyledLogoSunpoint = styled(LogoSunpoint)`
  margin-bottom: ${(props) => props.theme.margin.large};
`
export const StyledListLink = styled(Link)`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.link.desktop.fontSize};
  line-height: ${(props) => props.theme.font.text.link.desktop.lineHeight};
  font-weight: 600;
  letter-spacing: ${(props) =>
    props.theme.font.text.link.desktop.letterSpacing};
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  color: ${(props) => props.theme.palette.default.black};
  text-decoration: none;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.default.black};
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  ${TextParagraphStyles()}
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.primary)
      ? 'white'
      : 'black'};
`
export const StyledMetaLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.default.black};
  margin-bottom: ${(props) => props.theme.margin.default};
  display: inline-flex;
  ${TextParagraphStyles()}
  margin-right: 0.625rem;
  ${userSelectNone()}
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.primary)
      ? 'white'
      : 'black'};
`

export const StyledHorizontalRule = styled(HorizontalRule)``
export const StyledButton = styled(Button)`
  margin-bottom: 0;
`

export const StyledIconClubCard = styled(IconClubCard)`
  margin-bottom: ${(props) => props.theme.margin.default};
  width: 2.5rem;
  height: 2.5rem;
  path  {
    fill: ${(props) => props.theme.palette.default.primary};
  }
`

export const StyledIconHeadset = styled(IconHeadset)`
  margin-bottom: ${(props) => props.theme.margin.default};
  width: 2.5rem;
  height: 2.5rem;
  path  {
    fill: ${(props) => props.theme.palette.default.primary};
  }
`

export const MetaLinkCol = styled(Col)`
  flex-direction: row;
`
export const Social = styled.div``
export const SocialButton = styled(LinkButton)`
  display: inline-flex;
`
export const StyledListItem = styled(ListItem)`
  p {
    font-weight: bold;
  }
`
export const Claim = styled(Text)`
  ${userSelectNone()}
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.primary)
      ? 'white'
      : 'black'};
`
export const Copyright = styled(Text)`
  ${userSelectNone()}
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.primary)
      ? 'white'
      : 'black'};
`
