import styled from 'styled-components'

import BackgroundSun from '@components/atoms/BackgroundSun'
import Box from '@components/atoms/Box'
import Headline from '@components/atoms/Headline'
import Text from '@components/atoms/Text'

import FormNewsletter from '../Forms/FormNewsletter'

export const Root = styled(Box)`
  position: relative;
  overflow: hidden;
  height: 100%;
  background: ${(props) => props.theme.palette.default.black};
  border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
  margin: 0;
`
export const StyledFormNewsletter = styled(FormNewsletter)`
  label,
  p {
    color: ${(props) => props.theme.palette.default.white};
  }
  a {
    color: ${(props) => props.theme.palette.default.white};
  }
`
export const StyledHeadline = styled(Headline)`
  color: ${(props) => props.theme.palette.default.white};
`
export const StyledText = styled(Text)`
  color: ${(props) => props.theme.palette.default.white};
`
export const StyledBackgroundSun = styled(BackgroundSun)`
  position: absolute;
  bottom: -576px;
  left: -576px;
  width: 1152px;
  height: 1152px;
  transform: scale(3);
  z-index: 1;
  display: block;
`
