import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconMail.styles'

interface Props extends PropsWithClassName {
  color?: string
}

const IconMail: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#292929'
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={props.className}
      data-testid={'icon-mail-root'}
    >
      <path
        d="M15.2098 14.4063V7.42879C15.2098 7.22943 14.9959 7.10317 14.8214 7.19948L8.50612 10.6839C8.19112 10.8577 7.80894 10.8577 7.49394 10.6839L1.17865 7.19947C1.0041 7.10316 0.790222 7.22942 0.790222 7.42878L0.790238 14.4063C0.790238 14.725 0.916838 15.0307 1.14219 15.256C1.36754 15.4814 1.67318 15.6079 1.99187 15.6079H14.0082C14.3269 15.6079 14.6325 15.4814 14.8578 15.256C15.0832 15.0307 15.2098 14.725 15.2098 14.4063Z"
        fill={currentColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33333 0.666504C3.91657 0.666504 3.51686 0.832064 3.22216 1.12676C2.92747 1.42146 2.7619 1.82117 2.7619 2.23793V6.97453L7.37036 9.51723C7.68537 9.69104 8.06754 9.69104 8.38255 9.51723L13.2381 6.8382V2.23793C13.2381 1.82116 13.0726 1.42146 12.7779 1.12676C12.4832 0.832064 12.0834 0.666504 11.6667 0.666504H4.33333ZM5.06101 3.34259C5.06101 2.98098 5.35416 2.68783 5.71578 2.68783H10.2842C10.6458 2.68783 10.9389 2.98098 10.9389 3.34259C10.9389 3.7042 10.6458 3.99735 10.2842 3.99735H5.71578C5.35416 3.99735 5.06101 3.7042 5.06101 3.34259ZM5.06101 5.96163C5.06101 5.60003 5.35416 5.30687 5.71578 5.30687H10.2842C10.6458 5.30687 10.9389 5.60003 10.9389 5.96163C10.9389 6.32325 10.6458 6.6164 10.2842 6.6164H5.71578C5.35416 6.6164 5.06101 6.32325 5.06101 5.96163Z"
        fill={currentColor}
      />
    </Root>
  )
}

export { IconMail }
