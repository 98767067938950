import Link from 'next/link'
import { StoryblokPage } from 'pages/sunpoint'
import React, { PropsWithChildren, useCallback } from 'react'

import { ButtonModifier } from '@components/atoms/Button/Button'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconFacebook from '@components/atoms/Icons/IconFacebook'
import IconInstagram from '@components/atoms/Icons/IconInstagram'
import IconMail from '@components/atoms/Icons/IconMail'
import IconPhone from '@components/atoms/Icons/IconPhone'
import IconYoutube from '@components/atoms/Icons/IconYoutube'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'
import UnorderedList from '@components/atoms/UnorderedList'
import Newsletter from '@components/molecules/Newsletter'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import { themeSunpoint } from '@helper/theme'

import { RoutesSunpointDefault } from '@definitions/routes/Routes'
import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'

import {
  Claim,
  Copyright,
  FirstSection,
  LastText,
  MetaLinkCol,
  Root,
  SameHeightCol,
  Section,
  Social,
  SocialButton,
  StyledBox,
  StyledHorizontalRule,
  StyledIconClubCard,
  StyledIconHeadset,
  StyledLink,
  StyledListItem,
  StyledListLink,
  StyledLogoSunpoint,
  StyledMetaLink,
} from './FooterSunpoint.styles'

interface Props extends PropsWithClassName {
  menuData: StoryblokPage[]
}

const FooterSunpoint: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const cookieSettingsOnclick = useCallback((e: any) => {
    if (typeof window !== 'undefined' && 'UC_UI' in window) {
      e.preventDefault()
      const ucUi: any = window['UC_UI']
      ucUi.showSecondLayer()
    }
  }, [])

  return (
    <Root className={props.className} data-testid={'footer-sunpoint-root'}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Link aria-label="Home Link" href={RoutesSunpointDefault.INDEX}>
            <StyledLogoSunpoint height={35} />
          </Link>
        </Col>
      </Row>
      <FirstSection>
        <Row>
          <SameHeightCol xs={12} sm={12} md={6} lg={3}>
            <StyledBox>
              <StyledIconClubCard />
              <Headline importance={HeadlineImportances.h5}>
                Club Service
              </Headline>
              <Text variant={TextVariants.meta}>
                Für Fragen zu deinem Clubvertrag. Unsere Club FAQ-Seite kann
                schnell weiterhelfen!
              </Text>
              <UnorderedList>
                <StyledListItem
                  icon={
                    <StyledListLink
                      href={'mailto:club-service@sunpoint.de'}
                      aria-label="Club Service Mail"
                    >
                      <IconMail color={themeSunpoint.palette.default.primary} />
                    </StyledListLink>
                  }
                >
                  <StyledListLink
                    href={'mailto:club-service@sunpoint.de'}
                    aria-label="club-service@sunpoint.de"
                  >
                    club-service@sunpoint.de
                  </StyledListLink>
                </StyledListItem>
                <StyledListItem
                  icon={
                    <StyledListLink
                      href={'mailto:club-service@sunpoint.de'}
                      aria-label="Club Service Phone"
                    >
                      <IconPhone
                        color={themeSunpoint.palette.default.primary}
                      />
                    </StyledListLink>
                  }
                >
                  <StyledListLink
                    href={'tel:#49223498422655'}
                    aria-label="02234 98422 655"
                  >
                    02234 98422 655
                  </StyledListLink>
                </StyledListItem>
              </UnorderedList>
              <LastText variant={TextVariants.paragraph}>
                Mo. bis Fr. 10 – 16 Uhr
              </LastText>
            </StyledBox>
          </SameHeightCol>
          <SameHeightCol xs={12} sm={12} md={6} lg={3}>
            <StyledBox>
              <StyledIconHeadset />
              <Headline importance={HeadlineImportances.h5}>
                Allgemeiner Service
              </Headline>
              <Text variant={TextVariants.meta}>
                Für Fragen oder Anregungen zum Studio, unseren Produkten und
                Dienstleistungen.
              </Text>
              <UnorderedList>
                <StyledListItem
                  icon={
                    <StyledListLink
                      aria-label="Info Email"
                      href={'mailto:info@sunpoint.de'}
                    >
                      <IconMail color={themeSunpoint.palette.default.primary} />
                    </StyledListLink>
                  }
                >
                  <StyledListLink
                    href={'mailto:info@sunpoint.de'}
                    aria-label="info@sunpoint.de"
                  >
                    info@sunpoint.de
                  </StyledListLink>
                </StyledListItem>

                <StyledListItem
                  icon={
                    <StyledListLink
                      href={'tel:#49223498422333'}
                      aria-label="Info Phone"
                    >
                      <IconPhone
                        color={themeSunpoint.palette.default.primary}
                      />
                    </StyledListLink>
                  }
                >
                  <StyledListLink
                    href={'tel:#49223498422333'}
                    aria-label="02234 98422 333"
                  >
                    02234 98422 333
                  </StyledListLink>
                </StyledListItem>
              </UnorderedList>
              <LastText variant={TextVariants.paragraph}>
                Mo. bis Fr. 10 – 16 Uhr
              </LastText>
            </StyledBox>
          </SameHeightCol>
          <SameHeightCol xs={12} sm={12} md={12} lg={6}>
            <Newsletter list="sunpoint" inverted />
          </SameHeightCol>
        </Row>
      </FirstSection>
      <Section>
        <Row>
          <Col xs={12} sm={4} md={3} lg={3}>
            <StyledLink href={RoutesSunpoint.SUNPOINT__CLUBMITGLIEDWERDEN}>
              Clubmitglied werden
            </StyledLink>
            <StyledLink href={RoutesSunpoint.SUNPOINT__NEWSLETTER}>
              Newsletter
            </StyledLink>
            <StyledLink href={RoutesSunpoint.SUNPOINT__KOOPERATIONENINFLUENCER}>
              Kooperationen & Influencer
            </StyledLink>
            <StyledLink href={RoutesSunpoint.SUNPOINT__JOBS}>Jobs</StyledLink>
          </Col>
          <Col xs={12} sm={4} md={3} lg={3}>
            <StyledLink href={RoutesSunpoint.SUNPOINT__KONTAKT}>
              Kontakt
            </StyledLink>
            <StyledLink href={RoutesSunpoint.SUNPOINT__UEBERSUNPOINT}>
              Über SUNPOINT
            </StyledLink>
            <StyledLink href={RoutesSunpoint.SUNPOINT__CLUBVERTRAGKUENDIGEN}>
              Clubvertrag kündigen
            </StyledLink>
            <StyledLink href={RoutesSunpoint.SUNPOINT__PRESSE}>
              Presse
            </StyledLink>
          </Col>
          <Col xs={12} sm={4} md={3} lg={3}>
            <StyledLink
              href={
                RoutesSunpoint.SUNPOINT__UEBERSUNPOINT__EXPANSIONSTANDORTFUERSUNPOINTANBIETEN
              }
            >
              Standort anbieten
            </StyledLink>
            <StyledLink
              href={
                RoutesSunpoint.SUNPOINT__UEBERSUNPOINT__FRANCHISEPARTNERWERDEN
              }
            >
              Franchise-Partner werden
            </StyledLink>
            <StyledLink target="_blank" href={'https://b2b.sunpoint.de/'}>
              B2B-Shop
            </StyledLink>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3}>
            <Claim variant={TextVariants.lead}>Wir machen #Beauty</Claim>
            <Social>
              <SocialButton
                loading={false}
                href={RoutesSunpointDefault.FACEBOOK}
                disabled={false}
                modifier={ButtonModifier.TERTIARY}
                target="_blank"
                label={'Facebook Link'}
              >
                <IconFacebook />
              </SocialButton>
              <SocialButton
                loading={false}
                href={RoutesSunpointDefault.INSTAGRAM}
                disabled={false}
                modifier={ButtonModifier.TERTIARY}
                target="_blank"
                label={'Instagram Link'}
              >
                <IconInstagram />
              </SocialButton>
              <SocialButton
                loading={false}
                href={RoutesSunpointDefault.YOUTUBE}
                disabled={false}
                modifier={ButtonModifier.TERTIARY}
                target="_blank"
                label={'Youtube Link'}
              >
                <IconYoutube />
              </SocialButton>
            </Social>
          </Col>
        </Row>
      </Section>
      <Section>
        <StyledHorizontalRule />
      </Section>
      <Section>
        <Row>
          <Col xs={12} sm={12} md={5} lg={4}>
            <Copyright variant={TextVariants.paragraph}>
              &copy; {new Date().getFullYear()} SUNPOINT
            </Copyright>
          </Col>
          <MetaLinkCol xs={12} sm={12} md={7} lg={8}>
            <StyledMetaLink href={RoutesSunpoint.SUNPOINT__AGB}>
              AGB
            </StyledMetaLink>
            <StyledMetaLink href={RoutesSunpoint.SUNPOINT__WIDERRUFSBELEHRUNG}>
              Widerrufsbelehrung
            </StyledMetaLink>
            <StyledMetaLink href={RoutesSunpoint.SUNPOINT__IMPRESSUM}>
              Impressum
            </StyledMetaLink>
            <StyledMetaLink href={RoutesSunpoint.SUNPOINT__DATENSCHUTZ}>
              Datenschutz
            </StyledMetaLink>
            <StyledMetaLink onClick={cookieSettingsOnclick} href={'#'}>
              Cookie-Einstellungen
            </StyledMetaLink>
          </MetaLinkCol>
        </Row>
      </Section>
    </Root>
  )
}

export { FooterSunpoint }
