import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconPhone.styles'

interface Props extends PropsWithClassName {
  color?: string
  size?: string
}

const IconPhone: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#292929'
  const currentSize = props.size ?? '16'
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width={currentSize}
      height={currentSize}
      viewBox="0 0 16 16"
      fill="none"
      className={props.className}
      data-testid={'icon-phone-root'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8025 8.72007L8.10895 9.48965C6.81404 9.85962 5.57551 8.75386 5.79691 7.42545L6.16667 5.20694V2.17246C6.16667 1.62018 6.36049 1.08788 6.71045 0.666504H2.50002C1.4875 0.666504 0.666687 1.48732 0.666687 2.49984V13.4998C0.666687 14.1199 1.04514 14.5806 1.34764 14.8385C1.5112 14.9779 1.69136 15.0932 1.86917 15.1766C2.03088 15.2524 2.25674 15.3332 2.50002 15.3332H9.30954C10.3221 15.3332 11.1429 14.5124 11.1429 13.4998V8.72007H10.8025ZM4.59528 12.1903C4.59528 11.7564 4.94706 11.4046 5.38099 11.4046H6.42861C6.86254 11.4046 7.21432 11.7564 7.21432 12.1903C7.21432 12.6243 6.86254 12.9761 6.42861 12.9761H5.38099C4.94706 12.9761 4.59528 12.6243 4.59528 12.1903ZM7.74924 8.2305L10.6191 7.41055H14.2858C14.5636 7.41055 14.8301 7.30017 15.0265 7.10371C15.2229 6.90724 15.3334 6.64078 15.3334 6.36293V2.17246C15.3334 1.89461 15.2229 1.62814 15.0265 1.43168C14.8301 1.23521 14.5636 1.12484 14.2858 1.12484H8.52385C8.246 1.12484 7.97953 1.23521 7.78306 1.43168C7.5866 1.62814 7.47623 1.89461 7.47623 2.17246V5.31531L7.08866 7.64073C7.02539 8.02029 7.37927 8.33622 7.74924 8.2305Z"
        fill={currentColor}
      />
    </Root>
  )
}

export { IconPhone }
