import { StoryblokPage } from 'pages/sunpoint'
import React, { PropsWithChildren, useEffect } from 'react'

import FooterSunpoint from '@components/organisms/FooterSunpoint'
import HeaderSunpoint from '@components/organisms/HeaderSunpoint'

import { getEnvironment } from '@helper/env'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Main, Root } from './SunpointTemplateDefault.styles'

interface Props extends PropsWithClassName {
  menuData: StoryblokPage[]
}

const SunpointTemplateDefault: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children, menuData } = props

  return (
    <Root
      className={props.className}
      data-testid={'sunpoint-template-default-root'}
    >
      <HeaderSunpoint menuData={menuData} />
      <Main>{children}</Main>
      <FooterSunpoint menuData={menuData} />
    </Root>
  )
}

export { SunpointTemplateDefault }
