import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconCoupons.styles'

interface Props extends PropsWithClassName {}

const IconCoupons: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className={props.className}
      data-testid={'icon-coupons-root'}
    >
      <path
        fill="#292929"
        d="m2.108 12.798 7.907 8.266-.616.643a.85.85 0 0 1-.662.293.908.908 0 0 1-.68-.293L.28 13.578a.993.993 0 0 1-.28-.711c0-.28.094-.511.28-.692l9.006-9.531c.174-.195.42-.351.737-.468.317-.116.594-.174.829-.174h1.585L2.107 12.8l.001-.002ZM23.031 2c.3 0 .535.085.708.253.174.17.261.384.261.644v7.621c0 .26-.056.552-.167.877a2.1 2.1 0 0 1-.447.78l-9.118 9.394a.898.898 0 0 1-.671.293.898.898 0 0 1-.672-.293L5.15 13.441c-.186-.195-.28-.41-.28-.644 0-.235.094-.443.28-.624l9.006-9.531c.174-.195.42-.35.737-.467.317-.117.594-.175.829-.175h7.31Zm-4.513 7.621c.497 0 .926-.188 1.288-.565a1.86 1.86 0 0 0 .54-1.326 1.9 1.9 0 0 0-.54-1.345c-.362-.378-.79-.566-1.288-.566s-.907.188-1.268.566a1.878 1.878 0 0 0-.54 1.345c0 .52.179.948.54 1.326.36.377.783.565 1.268.565Z"
      />
    </Root>
  )
}

export { IconCoupons }
