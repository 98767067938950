import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
export const Main = styled.main`
  padding-top: 130px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 60;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    padding-top: 100px;
  }
`
