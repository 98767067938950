import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconYoutube.styles'

interface Props extends PropsWithClassName {}

const IconYoutube: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      className={props.className}
      data-testid={'icon-youtube-root'}
    >
      <path
        fill="#292929"
        fillRule="evenodd"
        d="M7.955 13.407v-5.98l5.227 2.99-5.227 2.99Zm11.627-7.861a2.52 2.52 0 0 0-1.768-1.79C16.254 3.334 10 3.334 10 3.334s-6.254 0-7.814.422a2.52 2.52 0 0 0-1.768 1.79C0 7.124 0 10.416 0 10.416s0 3.294.418 4.872a2.52 2.52 0 0 0 1.768 1.79C3.746 17.5 10 17.5 10 17.5s6.254 0 7.814-.423a2.52 2.52 0 0 0 1.768-1.789C20 13.71 20 10.417 20 10.417s0-3.293-.418-4.871Z"
        clipRule="evenodd"
      />
      <mask
        id="a"
        width="20"
        height="15"
        x="0"
        y="3"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
      >
        <path
          fill="#292929"
          fillRule="evenodd"
          d="M7.955 13.407v-5.98l5.227 2.99-5.227 2.99Zm11.627-7.861a2.52 2.52 0 0 0-1.768-1.79C16.254 3.334 10 3.334 10 3.334s-6.254 0-7.814.422a2.52 2.52 0 0 0-1.768 1.79C0 7.124 0 10.416 0 10.416s0 3.294.418 4.872a2.52 2.52 0 0 0 1.768 1.79C3.746 17.5 10 17.5 10 17.5s6.254 0 7.814-.423a2.52 2.52 0 0 0 1.768-1.789C20 13.71 20 10.417 20 10.417s0-3.293-.418-4.871Z"
          clipRule="evenodd"
        />
      </mask>
    </Root>
  )
}

export { IconYoutube }
