import Link from 'next/link'
import styled from 'styled-components'

import ListItem from '@components/atoms/ListItem'
import { TextVariants } from '@components/atoms/Text/Text'
import { getTextStyles } from '@components/atoms/Text/Text.styles'
import UnorderedList from '@components/atoms/UnorderedList'

import { userSelectNone } from '@helper/mixins'

interface RootProps {
  open: boolean
}

export const Root = styled(UnorderedList)<RootProps>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  margin: 0;
  flex-wrap: wrap;
  background: ${(props) => props.theme.palette.default.white};
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    min-width: auto;
    display: flex;
    height: 100%;
    min-width: auto;
    flex: 1;
    min-width: auto;
    padding: 0;
    position: relative;
    top: auto;
    left: auto;
  }
`

interface SubUnorderedListProps {
  visible: boolean
}
export const SubUnorderedList = styled(UnorderedList)<SubUnorderedListProps>`
  position: relative;
  z-index: 50;
  width: 100%;
  min-width: 100%;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0.7rem;
  background: ${(props) => props.theme.palette.default.white};
  overflow: hidden;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    margin-top: 0;
    position: absolute;
    top: 100px;
    min-width: auto;
    left: 0;
    width: 15.625rem;
    box-shadow: 0rem 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.05);
    border-radius: 0 0 1rem 1rem;
    margin-bottom: ${(props) => props.theme.margin.default};
  }
`
interface MainListItemProps {
  indicator: boolean | undefined
  active: boolean | undefined
}
export const MainListItem = styled(ListItem)<MainListItemProps>`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.7rem 0.15rem;
  flex-direction: column;
  border-bottom: 0.0625rem solid
    ${(props) => props.theme.palette.default.greyLight};
  align-items: center;
  &::before {
    display: none;
  }
  &:last-child {
    border-bottom: 0.0625rem solid transparent;
  }
  &:hover {
    background: ${(props) => props.theme.palette.default.greyLight};
  }
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    border-bottom: none;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: auto;
    padding: 0;
    &::before {
      display: none;
    }
    &:hover ${SubUnorderedList} {
      display: flex;
    }
  }
`

export const Trigger = styled.div<MainListItemProps>`
  display: ${(props) => (props.indicator ? 'flex' : 'none')};
  position: absolute;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 15px;
  right: 15px;
  content: '>';
  ${() => getTextStyles(TextVariants.paragraph)}
  font-weight: bold;
  transform-origin: center center;
  transform: rotate(${(props) => (props.active ? '180deg' : '0deg')});
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    display: none;
  }
`

export const SubListItem = styled(ListItem)`
  margin: 0;
  padding: 0;
  min-width: 100%;
  &:hover {
    background: ${(props) => props.theme.palette.default.greyLight};
  }
  &::before {
    display: none;
  }
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    border-bottom: 0.0625rem solid
      ${(props) => props.theme.palette.default.greyLight};
    &:last-child {
      border-bottom: 0.0625rem solid transparent;
    }
  }
`

export const MainStyledLink = styled(Link)`
  display: flex;
  font-family: ${(props) => props.theme.font.primaryFamily};
  text-decoration: none !important;
  color: ${(props) => props.theme.palette.default.black};
  padding: 0 0.5rem 0 0.5rem;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  ${userSelectNone()}
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    text-decoration: underline !important;
  }
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    padding: 0 0.3rem 0 0.3rem;
  }
`

export const SubStyledLink = styled(Link)`
  display: flex;
  margin-right: 0;
  font-family: ${(props) => props.theme.font.primaryFamily};
  text-decoration: none !important;
  color: ${(props) => props.theme.palette.default.black};
  padding: 0.7rem;
  width: 100%;
  ${() => getTextStyles(TextVariants.paragraph)}
  word-wrap: break-word;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    text-decoration: underline !important;
  }
`
