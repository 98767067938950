import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconFacebook.styles'

interface Props extends PropsWithClassName {}

const IconFacebook: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      className={props.className}
      data-testid={'icon-facebook-root'}
    >
      <path
        fill="#292929"
        fillRule="evenodd"
        d="m14.837 11.165.545-3.592H11.97V5.242c0-.983.476-1.941 2.005-1.941h1.551V.243S14.118 0 12.772 0c-2.81 0-4.648 1.72-4.648 4.835v2.738H5v3.592h3.124V20H11.97v-8.835h2.868Z"
        clipRule="evenodd"
      />
      <mask
        id="a"
        width="11"
        height="20"
        x="5"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
      >
        <path
          fill="#292929"
          fillRule="evenodd"
          d="m14.837 11.165.545-3.592H11.97V5.242c0-.983.476-1.941 2.005-1.941h1.551V.243S14.118 0 12.772 0c-2.81 0-4.648 1.72-4.648 4.835v2.738H5v3.592h3.124V20H11.97v-8.835h2.868Z"
          clipRule="evenodd"
        />
      </mask>
    </Root>
  )
}

export { IconFacebook }
