import useUserCentrics from '@hooks/useUserCentrics'
import React, { useEffect, useState } from 'react'

interface useSinchJobLayerWidgetReturnType {}

const useSinchJobLayerWidget = (): useSinchJobLayerWidgetReturnType => {
  const [loaded, setLoaded] = useState(false)
  const { consentToAll } = useUserCentrics()

  useEffect(() => {
    if (!consentToAll || loaded || typeof document === 'undefined') {
      return
    }

    setLoaded(true)
    const aTag = document.createElement('a')
    aTag.setAttribute('href', 'https://www.messengerpeople.com/')

    const script = document.createElement('script')
    script.setAttribute('data-widget', 'mpWidget')
    script.setAttribute('data-id', '1477362e3dc2e708ef9.95264158')
    script.setAttribute('src', 'https://cdn.messengerpeople.com/widget.chip.js')

    document.body.appendChild(aTag)
    document.body.appendChild(script)
  }, [consentToAll])

  return {}
}

export default useSinchJobLayerWidget
