import useUserCentrics from '@hooks/useUserCentrics'
import { useLoadScript } from '@react-google-maps/api'
import React, { useEffect, useMemo, useState } from 'react'

import GoogleMapApiContext from './context/GoogleMapApiContext'

interface PropsInner {
  setGoogleApiLoaded: (googleApiLoaded: boolean) => void
}

const GoogleApiScriptLoaderWrapperInner = (
  props: PropsInner
): React.ReactElement => {
  const libraries = useMemo(() => ['places'], [])
  const { setGoogleApiLoaded } = props

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.googlemapsApiKey as string,
    libraries: libraries as any,
  })

  useEffect(() => {
    setGoogleApiLoaded(isLoaded)
  }, [isLoaded])

  return <></>
}

interface Props {
  children?: React.ReactNode
}

const GoogleApiScriptLoaderWrapper = (props: Props): React.ReactElement => {
  const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false)
  const { consentGoogleMaps } = useUserCentrics()

  return (
    <GoogleMapApiContext.Provider
      value={{ setGoogleApiLoaded, googleApiLoaded }}
    >
      {consentGoogleMaps && (
        <GoogleApiScriptLoaderWrapperInner
          setGoogleApiLoaded={setGoogleApiLoaded}
        />
      )}
      {props.children}
    </GoogleMapApiContext.Provider>
  )
}

export default GoogleApiScriptLoaderWrapper
