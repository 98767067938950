import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconClubCard.styles'

interface Props extends PropsWithClassName {}

const IconClubCard: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={props.className}
      data-testid={'icon-club-card-root'}
    >
      <g clipPath="url(#a)">
        <path
          fill="#292929"
          d="m.557 10.945-.5-1.895.02.02A2.03 2.03 0 0 1 0 8.523c0-.468.14-.881.423-1.24.282-.358.64-.595 1.075-.713l16.627-4.512a.987.987 0 0 1 .249-.048c.09-.007.178-.01.268-.01.46 0 .867.14 1.22.42.352.28.584.642.7 1.083l.5 1.875L.557 10.945Zm23.368 4.277v-.02a2.027 2.027 0 0 1 .077.546c0 .469-.141.882-.423 1.24a2.003 2.003 0 0 1-1.057.714L6.451 21.998l.02-.02a2.63 2.63 0 0 0 .97-.946c.25-.41.374-.869.374-1.377v-.02c0-.755-.258-1.397-.778-1.923a2.556 2.556 0 0 0-1.89-.792c-.589 0-1.115.175-1.575.527a2.72 2.72 0 0 0-.96 1.366l-.538-2.011v-.02a.115.115 0 0 0 .067-.02c.02-.014.035-.027.048-.039a.938.938 0 0 0 .201-.293.89.89 0 0 0 .068-.352.913.913 0 0 0-.068-.341.734.734 0 0 0-.201-.284l-.634-.645-.711-2.616 20.602-5.547 2.477 8.575.002.002Zm-17.53 4.433a1.23 1.23 0 0 0-.366-.898 1.197 1.197 0 0 0-.883-.372c-.345 0-.64.123-.883.372a1.23 1.23 0 0 0-.365.898c0 .352.121.632.365.878.243.247.538.372.883.372.346 0 .641-.123.883-.372.243-.246.366-.54.366-.878Zm-.326-6.27a.948.948 0 0 0-.289-.595.844.844 0 0 0-.614-.243.855.855 0 0 0-.605.243.861.861 0 0 0-.278.595v1.797a.848.848 0 0 0 .278.595.851.851 0 0 0 .605.243.851.851 0 0 0 .614-.243.964.964 0 0 0 .289-.595v-1.797Zm2.015 3.32a.956.956 0 0 0 .279.195.817.817 0 0 0 .355.078.823.823 0 0 0 .356-.078c.108-.052.208-.123.298-.215l1.786-1.797a.913.913 0 0 0 .182-.977.881.881 0 0 0-.182-.273.89.89 0 0 0-.99-.205.71.71 0 0 0-.278.205l-1.786 1.777a.853.853 0 0 0-.182.283.965.965 0 0 0 0 .704c.044.11.105.205.182.283l-.02.02Zm3.303 3.828a.85.85 0 0 0 .624-.263.867.867 0 0 0 .26-.635.876.876 0 0 0-.26-.635.84.84 0 0 0-.624-.263H9.601a.847.847 0 0 0-.624.263.87.87 0 0 0-.259.635c0 .247.087.458.26.635.171.177.38.263.623.263h1.806-.02Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 2h24v20H0z" />
        </clipPath>
      </defs>
    </Root>
  )
}

export { IconClubCard }
