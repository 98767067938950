import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { useTheme } from 'styled-components'

export interface MetaTagsProps {
  _uid?: string
  title?: string
  plugin?: string
  og_image?: string
  og_title?: string
  description?: string
  twitter_image?: string
  twitter_title?: string
  og_description?: string
  twitter_description?: string
}

const BackupMetaSunpoint: React.FC = () => {
  return (
    <>
      <title>
        Solarium, Bräune, Bodyforming, Kosmetik | SUNPOINT Sonnenstudios
      </title>
      <meta
        name="description"
        content="✸ SUNPOINT - Deutschlands Sonnenstudiokette Nr.1! ✸ ✅Über 200 Sonnenstudios ✅Exklusive Sonnenbänke &amp; Solarkosmetik ✅Perfekter Service ✅ Beauty-Konzept"
      />
      <meta
        property="og:title"
        content="Solarium, Bräune, Bodyforming, Kosmetik | SUNPOINT Sonnenstudios"
      />
      <meta
        property="og:description"
        content="✸ SUNPOINT - Deutschlands Sonnenstudiokette Nr.1! ✸ ✅Über 200 Sonnenstudios ✅Exklusive Sonnenbänke &amp; Solarkosmetik ✅Perfekter Service ✅ Beauty-Konzept"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://example.com/path/to/image.jpg"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content="https://www.sunpoint.de" />
      <meta property="og:site_name" content="SUNPOINT Sonnenstudios" />
      <meta property="og:locale" content="de_DE" />
      <meta
        property="twitter:title"
        content="Solarium, Bräune, Bodyforming, Kosmetik | SUNPOINT Sonnenstudios"
      />
      <meta
        property="twitter:description"
        content="✸ SUNPOINT - Deutschlands Sonnenstudiokette Nr.1! ✸ ✅Über 200 Sonnenstudios ✅Exklusive Sonnenbänke &amp; Solarkosmetik ✅Perfekter Service ✅ Beauty-Konzept"
      />
      <meta
        property="twitter:image"
        content="https://example.com/path/to/twitter-image.jpg"
      />
      <meta property="twitter:card" content="summary_large_image" />
    </>
  )
}

const BackupMetaWellmaxx: React.FC = () => {
  return (
    <>
      <title>
        Innovatives &amp; effektives Körper-Abnehm-Programm | WELLMAXX
        bodyforming
      </title>
      <meta
        name="description"
        content="✅ Gewichtsreduktion ✅ Körperstraffung ✅ lymphaktivierende Massage ✅ Anti-Cellulite ✅ Fettverbrennung ✅ figurformende Kosmetik ✅ über 100 Studios bundesweit"
      />
      <meta
        property="og:title"
        content="Innovatives &amp; effektives Körper-Abnehm-Programm | WELLMAXX bodyforming"
      />
      <meta
        property="og:description"
        content="✅ Gewichtsreduktion ✅ Körperstraffung ✅ lymphaktivierende Massage ✅ Anti-Cellulite ✅ Fettverbrennung ✅ figurformende Kosmetik ✅ über 100 Studios bundesweit"
      />
      <meta
        property="og:image"
        content="https://web.archive.org/web/20240102104936im_/https://www.wellmaxx-bodyforming.com/fileadmin/_processed_/6/4/csm_WELLMAXX-bodyforming-Konzeptkreis_97bc8d9edf.jpg"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.wellmaxx-bodyforming.com" />
      <meta property="og:site_name" content="WELLMAXX bodyforming" />
      <meta property="og:locale" content="de_DE" />
      <meta
        property="twitter:title"
        content="Innovatives &amp; effektives Körper-Abnehm-Programm | WELLMAXX bodyforming"
      />
      <meta
        property="twitter:description"
        content="✅ Gewichtsreduktion ✅ Körperstraffung ✅ lymphaktivierende Massage ✅ Anti-Cellulite ✅ Fettverbrennung ✅ figurformende Kosmetik ✅ über 100 Studios bundesweit"
      />
      <meta
        property="twitter:image"
        content="https://www.wellmaxx-bodyforming.com/fileadmin/_processed_/7/0/csm_WELLMAXX-bodyforming-Konzeptkreis_02_c56a5a401f.jpg"
      />
      <meta property="twitter:card" content="summary_large_image" />
    </>
  )
}

const MetaTags: React.FC<MetaTagsProps> = (props: MetaTagsProps) => {
  const {
    title,
    og_image,
    og_title,
    og_description,
    description,
    twitter_description,
    twitter_image,
    twitter_title,
  } = props

  const theme: any = useTheme()
  const router = useRouter()

  const renderSunpointMetaTags = () => {
    return (
      <>
        <meta property="og:url" content="https://www.sunpoint.de" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SUNPOINT Sonnenstudios" />
        <meta property="og:locale" content="de_DE" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/sunpoint/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/sunpoint/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/sunpoint/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/sunpoint/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/sunpoint/safari-pinned-tab.svg"
          color="#f6c500"
        />
        <link rel="shortcut icon" href="/favicons/sunpoint/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-config"
          content="/favicons/sunpoint/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
        <link rel="canonical" href={`${process.env.appUrl}${router.asPath}`} />
      </>
    )
  }

  const renderWellmaxxMetaTags = () => {
    return (
      <>
        <meta
          property="og:url"
          content="https://www.wellmaxx-bodyforming.com"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="WELLMAXX bodyforming" />
        <meta property="og:locale" content="de_DE" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/wellmaxx/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/wellmaxx/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/wellmaxx/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/wellmaxx/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/wellmaxx/safari-pinned-tab.svg"
          color="#981858"
        />
        <link rel="shortcut icon" href="/favicons/wellmaxx/favicon.ico" />
        <meta name="msapplication-TileColor" content="#981858" />
        <meta
          name="msapplication-config"
          content="/favicons/wellmaxx/browserconfig.xml"
        />
        <meta name="theme-color" content="#981858" />
        <link rel="canonical" href={`${process.env.appUrl}${router.asPath}`} />
      </>
    )
  }

  return (
    <Head>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {og_title && <meta property="og:title" content={og_title} />}
      {og_description && (
        <meta property="og:description" content={og_description} />
      )}
      {og_image && <meta property="og:image" content={og_image} />}
      {twitter_title && <meta name="twitter:title" content={twitter_title} />}
      {twitter_description && (
        <meta name="twitter:description" content={twitter_description} />
      )}
      {twitter_image && <meta name="twitter:image" content={twitter_image} />}
      {theme.key === 'sunpoint' && renderSunpointMetaTags()}
      {theme.key === 'wellmaxx' && renderWellmaxxMetaTags()}
    </Head>
  )
}

export { MetaTags, BackupMetaSunpoint, BackupMetaWellmaxx }
