import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconHeadset.styles'

interface Props extends PropsWithClassName {}

const IconHeadset: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={props.className}
      data-testid={'icon-headset-root'}
    >
      <path
        fill="#292929"
        d="M4.5 18h-.75a3 3 0 0 1-3-3v-3a3 3 0 0 1 3-3h.75a.75.75 0 0 1 .75.75v7.5a.75.75 0 0 1-.75.75Z"
      />
      <path
        fill="#292929"
        fillRule="evenodd"
        d="M3.75 9.75A2.25 2.25 0 0 0 1.5 12v3a2.25 2.25 0 0 0 2.25 2.25h.75v-7.5h-.75Zm-2.652-.402A3.75 3.75 0 0 1 3.75 8.25h.75A1.5 1.5 0 0 1 6 9.75v7.5a1.5 1.5 0 0 1-1.5 1.5h-.75A3.75 3.75 0 0 1 0 15v-3a3.75 3.75 0 0 1 1.098-2.652Z"
        clipRule="evenodd"
      />
      <path
        fill="#292929"
        d="M20.25 18h-.75a.75.75 0 0 1-.75-.75v-7.5A.75.75 0 0 1 19.5 9h.75a3 3 0 0 1 3 3v3a3 3 0 0 1-3 3Z"
      />
      <path
        fill="#292929"
        fillRule="evenodd"
        d="M18.44 8.69a1.5 1.5 0 0 1 1.06-.44h.75A3.75 3.75 0 0 1 24 12v3a3.75 3.75 0 0 1-3.75 3.75h-.75a1.5 1.5 0 0 1-1.5-1.5v-7.5c0-.398.158-.78.44-1.06Zm1.81 1.06h-.75v7.5h.75A2.25 2.25 0 0 0 22.5 15v-3a2.25 2.25 0 0 0-2.25-2.25Z"
        clipRule="evenodd"
      />
      <path
        fill="#292929"
        fillRule="evenodd"
        d="M12 1.5A7.5 7.5 0 0 0 4.5 9 .75.75 0 0 1 3 9a9 9 0 1 1 18 0 .75.75 0 0 1-1.5 0A7.5 7.5 0 0 0 12 1.5ZM20.25 17.25A.75.75 0 0 1 21 18v.75a3.75 3.75 0 0 1-3.75 3.75H15a.75.75 0 0 1 0-1.5h2.25a2.25 2.25 0 0 0 2.25-2.25V18a.75.75 0 0 1 .75-.75Z"
        clipRule="evenodd"
      />
      <path
        fill="#292929"
        d="M13.5 23.25H12a1.5 1.5 0 1 1 0-3h1.5a1.5 1.5 0 1 1 0 3Z"
      />
      <path
        fill="#292929"
        fillRule="evenodd"
        d="M12 21a.75.75 0 1 0 0 1.5h1.5a.75.75 0 1 0 0-1.5H12Zm-1.591-.841A2.25 2.25 0 0 1 12 19.5h1.5a2.25 2.25 0 0 1 0 4.5H12a2.25 2.25 0 0 1-1.591-3.841Z"
        clipRule="evenodd"
      />
    </Root>
  )
}

export { IconHeadset }
