import useUserCentrics from '@hooks/useUserCentrics'
import { useEffect, useRef } from 'react'
import TagManager from 'react-gtm-module'
import { useTheme } from 'styled-components'

interface useTagmanagerReturnType {}

const useTagmanager = (): useTagmanagerReturnType => {
  const tagManagerLoaded = useRef<boolean>(false)
  const { consentGoogleMaps } = useUserCentrics()
  const theme: any = useTheme()

  useEffect(() => {
    if (
      typeof document === 'undefined' ||
      tagManagerLoaded.current ||
      !consentGoogleMaps
    ) {
      return
    }
    const tagManagerArgs = {
      gtmId:
        'GTM-' +
        (theme.key === 'sunpoint'
          ? process.env.googleTagmanagerCodeSunpoint
          : process.env.googleTagmanagerCodeWellmaxx),
    }

    TagManager.initialize(tagManagerArgs)
    tagManagerLoaded.current = true
  }, [consentGoogleMaps])

  return {}
}

export default useTagmanager
