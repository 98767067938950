import React, { PropsWithChildren, useState } from 'react'

import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { FormNewsletterType } from '../Forms/FormNewsletter/FormNewsletter'
import {
  Root,
  StyledBackgroundSun,
  StyledFormNewsletter,
  StyledHeadline,
  StyledText,
} from './Newsletter.styles'

interface Props extends PropsWithClassName {
  list: string
  inverted?: boolean
}

const Newsletter: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { list } = props
  const [subscribed, setSubscribed] = useState<boolean>(false)

  const handleSubmit = (data: FormNewsletterType) => {
    setSubscribed(true)
  }

  return (
    <Root className={props.className} dataTestId={'newsletter-root'}>
      <StyledBackgroundSun />
      <StyledHeadline importance={HeadlineImportances.h5}>
        {list.toUpperCase()} Newsletter
      </StyledHeadline>
      {!subscribed && (
        <>
          <StyledText variant={TextVariants.paragraph}>
            Jetzt anmelden und <b>5€ Gutschein</b> für unseren Online-Shop
            sichern.
          </StyledText>
          <br />
          <StyledFormNewsletter
            formId="footer"
            list={list}
            inverted={props.inverted ?? false}
            handleSubmitForm={(data: FormNewsletterType) => handleSubmit(data)}
          />
        </>
      )}
      {subscribed && (
        <>
          <StyledText variant={TextVariants.lead}>
            <b>Erfolgreich angemeldet</b>
          </StyledText>
          <StyledText variant={TextVariants.paragraph}>
            Bitte bestätige deine Anmeldung über den Link in der E-Mail, die wir
            dir gerade geschickt haben.
          </StyledText>
        </>
      )}
    </Root>
  )
}

export { Newsletter }
