import styled from 'styled-components'

interface BurgerProps {
  open: boolean
}

const burgerElementHeight = 1.86
const burgerElementMargin = 6
const burgerRatio = 1.8

const burgerDimension = 3 * burgerElementHeight + 2 * burgerElementMargin

const width = burgerDimension * Math.round(burgerRatio) + 'px'
const height = burgerDimension + burgerElementMargin * 2 + 'px'

export const TopBun = styled.div<BurgerProps>`
  position: absolute;
  top: ${burgerElementMargin + 'px'};
  left: 0;
  width: ${width};
  height: ${burgerElementHeight + 'px'};
  background: ${(props) => props.theme.palette.default.black};
  opacity: ${(props) => (props.open ? 0 : 1)};
  transition: all 200ms ease-in-out;
  transform-origin: center center;
`

export const PattyOne = styled.div<BurgerProps>`
  position: absolute;
  top: ${burgerElementHeight + burgerElementMargin * 2 + 'px'};
  left: 0;
  width: ${width};
  height: ${burgerElementHeight + 'px'};
  background: ${(props) => props.theme.palette.default.black};
  transition: all 100ms ease-in-out;
  transform: rotate(${(props) => (props.open ? '45deg' : '0deg')});
  transform-origin: center center;
`
export const PattyTwo = styled.div<BurgerProps>`
  position: absolute;
  top: ${burgerElementHeight + burgerElementMargin * 2 + 'px'};
  left: 0;
  width: ${width};
  height: ${burgerElementHeight + 'px'};
  background: ${(props) => props.theme.palette.default.black};
  transition: all 200ms ease-in-out;
  transform: rotate(${(props) => (props.open ? '-45deg' : '0deg')});
  transform-origin: center center;
`

export const BottomBun = styled.div<BurgerProps>`
  position: absolute;
  top: ${burgerElementHeight * 2 + burgerElementMargin * 3 + 'px'};
  left: 0;
  width: ${width};
  height: ${burgerElementHeight + 'px'};
  opacity: ${(props) => (props.open ? 0 : 1)};
  background: ${(props) => props.theme.palette.default.black};
  transition: all 100ms ease-in-out;
  transform-origin: center center;
`

export const Root = styled.div<BurgerProps>`
  width: ${width};
  height: ${height};
  display: block;
  cursor: pointer;
  z-index: 999;
  display: flex;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
  }
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    display: none;
  }
  &:hover {
    ${BottomBun} {
      width: ${(props) =>
        props.open
          ? width
          : burgerDimension * Math.round(burgerRatio) * 0.85 + 'px'};
    }
  }
`
