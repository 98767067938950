import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { BottomBun, PattyOne, PattyTwo, Root, TopBun } from './Burger.styles'

interface Props extends PropsWithClassName {
  open: boolean
  onClick: () => void
}

const Burger: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { open, onClick } = props
  return (
    <Root
      open={open}
      onClick={onClick}
      className={props.className}
      data-testid={'burger-root'}
    >
      <TopBun open={open}></TopBun>
      <PattyOne open={open}></PattyOne>
      <PattyTwo open={open}></PattyTwo>
      <BottomBun open={open}></BottomBun>
    </Root>
  )
}

export { Burger }
