interface useRedirectStandardReturnType {}

const useRedirectToStandard = (): useRedirectStandardReturnType => {
  const redirects: any = {
    'https://sunpoint.de': 'https://www.sunpoint.de',
    'https://wellmaxx-bodyforming.com': 'https://www.wellmaxx-bodyforming.com',
    'http://sunpoint.de': 'https://www.sunpoint.de',
    'http://wellmaxx-bodyforming.com': 'https://www.wellmaxx-bodyforming.com',
  }

  for (let redirect in redirects) {
    if (typeof document === 'undefined') {
      continue
    }
    if (document.location.href.indexOf(redirect) === 0) {
      document.location.href =
        redirects[redirect] + document.location.href.substr(redirect.length)

      break
    }
  }

  return {}
}

export default useRedirectToStandard
