import { StoryblokPage } from 'pages/sunpoint'
import React, { PropsWithChildren, useState } from 'react'

import IconAccordionArrow from '@components/atoms/Icons/IconAccordionArrow'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  MainListItem,
  MainStyledLink,
  Root,
  SubListItem,
  SubStyledLink,
  SubUnorderedList,
  Trigger,
} from './HeaderNavigationSunpoint.styles'

interface Props extends PropsWithClassName {
  menuData: StoryblokPage[]
  open: boolean
  closeMenu: () => void
}

const HeaderNavigationSunpoint: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null)
  const { open, closeMenu } = props

  const handleCloseMenu = () => {
    setActiveSubmenu(null)
    closeMenu()
  }
  const handleToggleSubmenu = (index: number) => {
    if (index == activeSubmenu) {
      setActiveSubmenu(null)
      return
    }
    setActiveSubmenu(index)
  }

  return (
    <Root
      className={props.className}
      dataTestId={'header-navigation-sunpoint-root'}
      open={open}
      onMouseLeave={() => handleCloseMenu()}
    >
      {props.menuData.map(
        (menuItem, index) =>
          menuItem.real_path !== '/sunpoint' &&
          menuItem.real_path !== '/wellmaxx' && (
            <MainListItem
              indicator={menuItem.children && menuItem.children.length > 1}
              active={activeSubmenu === index}
              onClick={() => handleToggleSubmenu(index)}
              key={menuItem.id + menuItem.name + index}
            >
              <MainStyledLink
                onClick={() => handleCloseMenu()}
                passHref
                href={menuItem.real_path}
              >
                {menuItem.name}
              </MainStyledLink>

              <SubUnorderedList visible={activeSubmenu === index}>
                {(menuItem.children ?? []).map(
                  (submenuItem) =>
                    !submenuItem.is_startpage && (
                      <SubListItem
                        key={
                          menuItem.id +
                          menuItem.name +
                          submenuItem.id +
                          submenuItem.name
                        }
                      >
                        <SubStyledLink
                          passHref
                          href={submenuItem.real_path}
                          onClick={() => handleCloseMenu()}
                        >
                          {submenuItem.name}
                        </SubStyledLink>
                      </SubListItem>
                    )
                )}

                {menuItem.real_path === '/sunpoint/bodyforming' && (
                  <>
                    <SubListItem key={'wellmaxx-bodystyle'}>
                      <SubStyledLink
                        passHref
                        href={
                          'https://www.wellmaxx-bodyforming.com/wellmaxx/koerperstraffung/wellmaxx-bodystyle'
                        }
                        onClick={() => handleCloseMenu()}
                      >
                        WELLMAXX bodystyle
                      </SubStyledLink>
                    </SubListItem>
                    <SubListItem key={'wellmaxx-bodyslim'}>
                      <SubStyledLink
                        passHref
                        href={
                          'https://www.wellmaxx-bodyforming.com/wellmaxx/gewichtsreduktion/wellmaxx-bodyslim-v2'
                        }
                        onClick={() => handleCloseMenu()}
                      >
                        WELLMAXX bodyslim V2
                      </SubStyledLink>
                    </SubListItem>
                  </>
                )}
              </SubUnorderedList>

              <Trigger
                indicator={menuItem.children && menuItem.children.length > 1}
                active={activeSubmenu === index}
              >
                <IconAccordionArrow />
              </Trigger>
            </MainListItem>
          )
      )}
    </Root>
  )
}

export { HeaderNavigationSunpoint }
