import { useRouter } from 'next/router'
import { StoryblokPage } from 'pages/sunpoint'
import React, { PropsWithChildren, useState } from 'react'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import IconClubCard from '@components/atoms/Icons/IconClubCard'
import IconCoupons from '@components/atoms/Icons/IconCoupons'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { RoutesSunpointDefault } from '@definitions/routes/Routes'
import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'

import {
  Buttons,
  Logo,
  Root,
  StyledBurger,
  StyledButton,
  StyledHeaderNavigationSunpoint,
  StyledLink,
  StyledLogoSunpoint,
  StyledSpan,
} from './HeaderSunpoint.styles'

interface Props extends PropsWithClassName {
  menuData: StoryblokPage[]
}

const HeaderSunpoint: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const router = useRouter()
  const [open, setOpen] = useState<boolean>(false)
  const handleClick = (): void => {
    setOpen(!open)
  }
  const closeMenu = () => {
    setOpen(false)
  }
  return (
    <Root
      open={open}
      className={props.className}
      data-testid={'header-sunpoint-root'}
    >
      <Logo>
        <StyledLink aria-label="Home Link" href={RoutesSunpointDefault.INDEX}>
          <StyledLogoSunpoint height={40} />
        </StyledLink>
      </Logo>
      <StyledHeaderNavigationSunpoint
        closeMenu={() => closeMenu()}
        open={open}
        menuData={props.menuData}
      />
      <Buttons open={open}>
        <StyledButton
          modifier={ButtonModifier.PRIMARY}
          disabled={false}
          buttonType={ButtonTypes.BUTTON}
          icon={<IconClubCard />}
          iconAlign={IconAligns.LEFT}
          loading={false}
          onClick={() =>
            router.push(RoutesSunpoint.SUNPOINT__CLUBMITGLIEDWERDEN)
          }
        >
          <span>Clubmitglied</span>
          <StyledSpan>&nbsp;werden</StyledSpan>
        </StyledButton>
        <StyledButton
          modifier={ButtonModifier.SECONDARY}
          disabled={false}
          buttonType={ButtonTypes.BUTTON}
          icon={<IconCoupons />}
          loading={false}
          iconAlign={IconAligns.LEFT}
          onClick={() => router.push(RoutesSunpoint.SUNPOINT__COUPONS)}
        >
          Coupons
        </StyledButton>
      </Buttons>
      <StyledBurger onClick={handleClick} open={open} />
    </Root>
  )
}

export { HeaderSunpoint }
