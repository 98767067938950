import useGoogleTracking from '@hooks/useGoogleTracking'
import useRedirectToStandard from '@hooks/useRedirectToStandard'
import useSinchJobLayerWidget from '@hooks/useSinchJobLayerWidget'
import useTagmanager from '@hooks/useTagmanager'
import useUserCentrics from '@hooks/useUserCentrics'
import {
  getStoryblokApi,
  ISbStoryData,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect } from 'react'

import Box from '@components/atoms/Box'
import {
  BackupMetaSunpoint,
  MetaTags,
  MetaTagsProps,
} from '@components/molecules/MetaTags/MetaTags'
import SunpointTemplateDefault from '@components/templates/SunpointTemplateDefault'

import RealEnvironmentContext from '@helper/context/RealEnviromentContext'
import GoogleApiScriptLoaderWrapper from '@helper/GoogleApiScriptLoaderWrapper'

interface StoryblokComponent {
  component: string
  _uid: string
  [key: string]: any
}

export interface StoryblokPage {
  id: number
  slug: string
  name: string
  is_folder: boolean
  parent_id: number
  published: boolean
  path: string | null
  position: number
  uuid: string
  is_startpage: boolean
  real_path: string
  children?: StoryblokPage[]
}

export function transformToNested(pages: StoryblokPage[]): StoryblokPage[] {
  pages = Object.values(pages)
  if (!pages || pages.length < 1) {
    return []
  }
  let nestedNav: StoryblokPage[] = []
  // Add folders first
  for (let page of pages) {
    if (page.is_folder && page.published) {
      nestedNav.push({
        ...page,
        children: [],
      })
    }
  }
  // Add other pages
  for (let page of pages) {
    let parentFolder = nestedNav.find(
      (navItem) => navItem.id === page.parent_id
    )
    if (parentFolder && page.published) {
      if (!parentFolder.children) {
        parentFolder.children = []
      }
      parentFolder.children.push(page)
    } else {
      // Handle links that don't have a parent folder
      if (page.published) {
        nestedNav.push(page)
      }
    }
  }
  return nestedNav
}

export interface PageProps {
  story: ISbStoryData<{
    body: StoryblokComponent[]
    metatags: MetaTagsProps
  }>
  menuData: StoryblokPage[]
}

export default function Home({
  story: initialStory,
  menuData,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const story = useStoryblokState(initialStory)

  useRedirectToStandard()
  useUserCentrics()
  useSinchJobLayerWidget()
  useTagmanager()
  const { trackPageView } = useGoogleTracking()
  const router = useRouter()

  useEffect(() => {
    trackPageView(router.asPath, story?.name ?? '')
  }, [router.asPath, story?.name, trackPageView])

  return (
    <SunpointTemplateDefault menuData={transformToNested(menuData)}>
      {story?.content.metatags !== undefined && (
        <MetaTags
          _uid={story?.content.metatags._uid}
          title={story?.content.metatags.title}
          plugin={story?.content.metatags.plugin}
          og_image={story?.content.metatags.og_image}
          og_title={story?.content.metatags.og_title}
          description={story?.content.metatags.description}
          twitter_image={story?.content.metatags.twitter_image}
          twitter_title={story?.content.metatags.twitter_title}
          og_description={story?.content.metatags.og_description}
          twitter_description={story?.content.metatags.twitter_description}
        />
      )}
      {story?.content.metatags === undefined && <BackupMetaSunpoint />}
      <Script
        data-settings-id={process.env.userCentricsId ?? ''}
        id="usercentrics-cmp"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
      ></Script>
      <GoogleApiScriptLoaderWrapper>
        <RealEnvironmentContext.Provider value={{ isRealEnvironment: true }}>
          {(!story || !story.content) && <Box>Loading...</Box>}
          {story && story.content && (
            <StoryblokComponent blok={story.content} />
          )}
        </RealEnvironmentContext.Provider>
      </GoogleApiScriptLoaderWrapper>
    </SunpointTemplateDefault>
  )
}

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const storyblokApi = getStoryblokApi()
  let story
  let links

  try {
    let { data: storyData } = await storyblokApi.get(
      `cdn/stories/sunpoint/home`,
      {
        version: 'draft',
      }
    )
    if (storyData && storyData.story) {
      story = storyData.story
    }
  } catch (error) {
    console.error('Fehler beim Abrufen der Story-Daten:', error)
  }

  try {
    let { data: linkData } = await storyblokApi.get('cdn/links', {
      version: 'draft',
      per_page: 5000,
      starts_with: 'sunpoint',
      is_startpage: true,
      with_tag: 'InHeaderNavigation',
    })
    if (linkData) {
      links = linkData.links
    }
  } catch (error) {
    console.error('Fehler beim Abrufen der Links:', error)
  }
  if (story && links) {
    return {
      props: {
        story: story,
        menuData: links,
      },
      revalidate: 20,
    }
  } else {
    return {
      props: {
        story: {
          alternates: [],
          content: { body: [] },
          created_at: '',
          full_slug: '',
          group_id: '',
          id: 0,
          name: '',
          parent_id: 0,
          position: 0,
          published: null,
          slug: '',
          sort_by_date: null,
          tag_list: [],
          uuid: '',
          is_startpage: false,
          meta_data: {},
          published_at: '',
          first_published_at: '',
          lang: '',
        },
        menuData: [],
      },
      revalidate: 20,
    }
  }
}
