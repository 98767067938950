import { StoryblokPage } from 'pages/sunpoint'

import { HeaderNavigationSunpoint } from './HeaderNavigationSunpoint'

export const dummyMenuData: StoryblokPage[] = [
  {
    id: 322563539,
    slug: 'sunpoint',
    name: 'SUNPOINT',
    is_folder: true,
    parent_id: 0,
    published: true,
    path: null,
    position: 10,
    uuid: 'b78d0975-f1ac-4155-b3d6-11c9ee262ad8',
    is_startpage: false,
    real_path: '/sunpoint',
    children: [
      {
        id: 322564270,
        slug: 'sunpoint/home',
        name: 'Home',
        is_folder: false,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 10,
        uuid: '50d1b16c-e98c-4255-a068-8fe147ec27db',
        is_startpage: false,
        real_path: '/sunpoint/home',
      },
      {
        id: 322782209,
        slug: 'sunpoint/braeune',
        name: 'Bräune',
        is_folder: true,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 20,
        uuid: '890a85f1-3998-4baa-8342-eb5946b72955',
        is_startpage: false,
        real_path: '/sunpoint/braeune',
      },
      {
        id: 322787013,
        slug: 'sunpoint/bodyforming',
        name: 'Bodyforming',
        is_folder: true,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 30,
        uuid: 'a227af2f-af1d-4fed-9e96-c4d572ee2f17',
        is_startpage: false,
        real_path: '/sunpoint/bodyforming',
      },
      {
        id: 322787151,
        slug: 'sunpoint/kosmetik',
        name: 'Kosmetik',
        is_folder: true,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 40,
        uuid: 'ef2e8cc6-d122-4dad-8232-046e070b87e8',
        is_startpage: false,
        real_path: '/sunpoint/kosmetik',
      },
      {
        id: 322787208,
        slug: 'sunpoint/club',
        name: 'Club',
        is_folder: true,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 50,
        uuid: '1056518b-8cb5-4051-bad5-163e1d93e009',
        is_startpage: false,
        real_path: '/sunpoint/club',
      },
      {
        id: 372055004,
        slug: 'sunpoint/coupons',
        name: 'Coupons',
        is_folder: true,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 60,
        uuid: '7bb91498-371e-41f3-93ed-69675a3f9435',
        is_startpage: false,
        real_path: '/sunpoint/coupons',
      },
      {
        id: 322787213,
        slug: 'sunpoint/ueber-sunpoint',
        name: 'Über SUNPOINT',
        is_folder: true,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 70,
        uuid: '8127bfe2-290b-4990-a04d-d909e1e638aa',
        is_startpage: false,
        real_path: '/sunpoint/ueber-sunpoint',
      },
      {
        id: 322787215,
        slug: 'sunpoint/jobs',
        name: 'Jobs',
        is_folder: true,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 80,
        uuid: 'b3c2e788-61ed-4215-a04c-f9222df5b52b',
        is_startpage: false,
        real_path: '/sunpoint/jobs',
      },
      {
        id: 324339210,
        slug: 'sunpoint/kontakt',
        name: 'Kontakt',
        is_folder: false,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 100,
        uuid: '3806c3c8-4d5f-4bba-b0d3-4a334250a712',
        is_startpage: false,
        real_path: '/sunpoint/kontakt',
      },
      {
        id: 324338695,
        slug: 'sunpoint/datenschutz',
        name: 'Datenschutz',
        is_folder: false,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 110,
        uuid: 'c47b71e9-ae34-4f14-91bc-9f6a3caff333',
        is_startpage: false,
        real_path: '/sunpoint/datenschutz',
      },
      {
        id: 324338457,
        slug: 'sunpoint/impressum',
        name: 'Impressum',
        is_folder: false,
        parent_id: 322563539,
        published: true,
        path: null,
        position: 120,
        uuid: 'dd4d707c-7191-41c5-9467-0f7cd96b314c',
        is_startpage: false,
        real_path: '/sunpoint/impressum',
      },
    ],
  },
  {
    id: 322782209,
    slug: 'sunpoint/braeune',
    name: 'Bräune',
    is_folder: true,
    parent_id: 322563539,
    published: true,
    path: null,
    position: 20,
    uuid: '890a85f1-3998-4baa-8342-eb5946b72955',
    is_startpage: false,
    real_path: '/sunpoint/braeune',
    children: [
      {
        id: 322782259,
        slug: 'sunpoint/braeune/',
        name: 'Bräune',
        is_folder: false,
        parent_id: 322782209,
        published: true,
        path: null,
        position: 10,
        uuid: 'aa50ff89-3062-426b-9c44-9ad8ec699036',
        is_startpage: true,
        real_path: '/sunpoint/braeune/',
      },
      {
        id: 322782588,
        slug: 'sunpoint/braeune/solarium',
        name: 'Solarium',
        is_folder: false,
        parent_id: 322782209,
        published: true,
        path: null,
        position: 20,
        uuid: '589e3df3-f7df-4728-989e-8cbdf37e6209',
        is_startpage: false,
        real_path: '/sunpoint/braeune/solarium',
      },
      {
        id: 322783311,
        slug: 'sunpoint/braeune/collagen',
        name: 'Collagen',
        is_folder: false,
        parent_id: 322782209,
        published: true,
        path: null,
        position: 30,
        uuid: '31930341-5c09-4626-ba70-a53ec1a94ee5',
        is_startpage: false,
        real_path: '/sunpoint/braeune/collagen',
      },
      {
        id: 322783705,
        slug: 'sunpoint/braeune/spray-tanning-braeunungsdusche',
        name: 'Spray Tanning Bräunungsdusche',
        is_folder: false,
        parent_id: 322782209,
        published: true,
        path: null,
        position: 40,
        uuid: 'd6f93c9f-af03-4d48-a77f-75234b2458dc',
        is_startpage: false,
        real_path: '/sunpoint/braeune/spray-tanning-braeunungsdusche',
      },
      {
        id: 322783857,
        slug: 'sunpoint/braeune/hauttypen-test',
        name: 'Hauttypen-Test',
        is_folder: false,
        parent_id: 322782209,
        published: true,
        path: null,
        position: 50,
        uuid: 'a9c26fc2-0022-4313-8963-c083c435d947',
        is_startpage: false,
        real_path: '/sunpoint/braeune/hauttypen-test',
      },
      {
        id: 322784190,
        slug: 'sunpoint/braeune/braeunungs-tipps',
        name: 'Bräunungs-Tipps',
        is_folder: false,
        parent_id: 322782209,
        published: true,
        path: null,
        position: 60,
        uuid: '0724659e-7336-4d98-a803-a4ec546e36d7',
        is_startpage: false,
        real_path: '/sunpoint/braeune/braeunungs-tipps',
      },
      {
        id: 322785615,
        slug: 'sunpoint/braeune/sonnenlicht-und-vitamin-d',
        name: 'Sonnenlicht und Vitamin D',
        is_folder: false,
        parent_id: 322782209,
        published: true,
        path: null,
        position: 70,
        uuid: 'a2b647ff-d542-4258-b5e3-3784f2fdbc62',
        is_startpage: false,
        real_path: '/sunpoint/braeune/sonnenlicht-und-vitamin-d',
      },
    ],
  },
  {
    id: 322787013,
    slug: 'sunpoint/bodyforming',
    name: 'Bodyforming',
    is_folder: true,
    parent_id: 322563539,
    published: true,
    path: null,
    position: 30,
    uuid: 'a227af2f-af1d-4fed-9e96-c4d572ee2f17',
    is_startpage: false,
    real_path: '/sunpoint/bodyforming',
    children: [
      {
        id: 322787490,
        slug: 'sunpoint/bodyforming/',
        name: 'Bodyforming',
        is_folder: false,
        parent_id: 322787013,
        published: true,
        path: null,
        position: -20,
        uuid: 'a6a271c7-950c-49df-b00a-024329c236ed',
        is_startpage: true,
        real_path: '/sunpoint/bodyforming/',
      },
    ],
  },
  {
    id: 322787151,
    slug: 'sunpoint/kosmetik',
    name: 'Kosmetik',
    is_folder: true,
    parent_id: 322563539,
    published: true,
    path: null,
    position: 40,
    uuid: 'ef2e8cc6-d122-4dad-8232-046e070b87e8',
    is_startpage: false,
    real_path: '/sunpoint/kosmetik',
    children: [
      {
        id: 322787899,
        slug: 'sunpoint/kosmetik/',
        name: 'Kosmetik',
        is_folder: false,
        parent_id: 322787151,
        published: true,
        path: null,
        position: -50,
        uuid: '2e21d561-6bfc-4fa0-9773-111f4d2d6535',
        is_startpage: true,
        real_path: '/sunpoint/kosmetik/',
      },
      {
        id: 322788294,
        slug: 'sunpoint/kosmetik/beauty-tipps',
        name: 'Beauty-Tipps',
        is_folder: false,
        parent_id: 322787151,
        published: true,
        path: null,
        position: -40,
        uuid: '9fa415e7-4bef-446a-9eca-ab71d01740e6',
        is_startpage: false,
        real_path: '/sunpoint/kosmetik/beauty-tipps',
      },
      {
        id: 380809534,
        slug: 'sunpoint/kosmetik/solarkosmetik',
        name: 'Solarkosmetik',
        is_folder: false,
        parent_id: 322787151,
        published: true,
        path: null,
        position: -20,
        uuid: 'dd9d5175-2237-4b28-b5cc-c939d8c71f21',
        is_startpage: false,
        real_path: '/sunpoint/kosmetik/solarkosmetik',
      },
      {
        id: 322788261,
        slug: 'sunpoint/kosmetik/wirkkosmetik',
        name: 'Wirkkosmetik',
        is_folder: false,
        parent_id: 322787151,
        published: true,
        path: null,
        position: -20,
        uuid: '7490d04b-6f83-413f-8766-a8ae3f1069e7',
        is_startpage: false,
        real_path: '/sunpoint/kosmetik/wirkkosmetik',
      },
      {
        id: 322787649,
        slug: 'sunpoint/kosmetik/monatsangebote',
        name: 'Monatsangebote',
        is_folder: false,
        parent_id: 322787151,
        published: true,
        path: null,
        position: 0,
        uuid: 'f8c9cb24-39f6-4518-a8e0-9924e1b14fec',
        is_startpage: false,
        real_path: '/sunpoint/kosmetik/monatsangebote',
      },
    ],
  },
  {
    id: 322787208,
    slug: 'sunpoint/club',
    name: 'Club',
    is_folder: true,
    parent_id: 322563539,
    published: true,
    path: null,
    position: 50,
    uuid: '1056518b-8cb5-4051-bad5-163e1d93e009',
    is_startpage: false,
    real_path: '/sunpoint/club',
    children: [
      {
        id: 322789695,
        slug: 'sunpoint/club/',
        name: 'SUNPOINT Club',
        is_folder: false,
        parent_id: 322787208,
        published: true,
        path: null,
        position: -100,
        uuid: '67aeda3d-6c68-4c01-a0ff-16c10d2946e3',
        is_startpage: true,
        real_path: '/sunpoint/club/',
      },
      {
        id: 322790567,
        slug: 'sunpoint/club/club-faq',
        name: 'Club-FAQ',
        is_folder: false,
        parent_id: 322787208,
        published: true,
        path: null,
        position: -90,
        uuid: '5f71c7cf-b089-42b4-b49c-74d991ceaf62',
        is_startpage: false,
        real_path: '/sunpoint/club/club-faq',
      },
      {
        id: 322790367,
        slug: 'sunpoint/club/kombi-club',
        name: 'Kombi-Club',
        is_folder: false,
        parent_id: 322787208,
        published: true,
        path: null,
        position: -70,
        uuid: 'dbf5e94c-45af-4cb0-b4d2-ac3886d4fef7',
        is_startpage: false,
        real_path: '/sunpoint/club/kombi-club',
      },
      {
        id: 322790228,
        slug: 'sunpoint/club/tantastic-club',
        name: 'TANTASTIC Club',
        is_folder: false,
        parent_id: 322787208,
        published: true,
        path: null,
        position: -50,
        uuid: 'abf12cbf-33c4-4ae1-b0e5-0b3eb846bcf9',
        is_startpage: false,
        real_path: '/sunpoint/club/tantastic-club',
      },
      {
        id: 322790159,
        slug: 'sunpoint/club/wellmaxx-club',
        name: 'WELLMAXX Club',
        is_folder: false,
        parent_id: 322787208,
        published: true,
        path: null,
        position: -30,
        uuid: 'dd435d99-8462-4ec1-972c-915727a00d82',
        is_startpage: false,
        real_path: '/sunpoint/club/wellmaxx-club',
      },
      {
        id: 322789921,
        slug: 'sunpoint/club/sunpoint-club',
        name: 'SUNPOINT Club',
        is_folder: false,
        parent_id: 322787208,
        published: true,
        path: null,
        position: -10,
        uuid: 'a40109f6-1f71-4391-9f00-48a670897230',
        is_startpage: false,
        real_path: '/sunpoint/club/sunpoint-club',
      },
    ],
  },
  {
    id: 372055004,
    slug: 'sunpoint/coupons',
    name: 'Coupons',
    is_folder: true,
    parent_id: 322563539,
    published: true,
    path: null,
    position: 60,
    uuid: '7bb91498-371e-41f3-93ed-69675a3f9435',
    is_startpage: false,
    real_path: '/sunpoint/coupons',
    children: [
      {
        id: 372055007,
        slug: 'sunpoint/coupons/',
        name: 'Coupons',
        is_folder: false,
        parent_id: 372055004,
        published: true,
        path: null,
        position: 0,
        uuid: '713b7576-ee7b-462a-8aff-ee319ffc7bb6',
        is_startpage: true,
        real_path: '/sunpoint/coupons/',
      },
    ],
  },
  {
    id: 322787213,
    slug: 'sunpoint/ueber-sunpoint',
    name: 'Über SUNPOINT',
    is_folder: true,
    parent_id: 322563539,
    published: true,
    path: null,
    position: 70,
    uuid: '8127bfe2-290b-4990-a04d-d909e1e638aa',
    is_startpage: false,
    real_path: '/sunpoint/ueber-sunpoint',
    children: [
      {
        id: 322795289,
        slug: 'sunpoint/ueber-sunpoint/franchise-partner-werden',
        name: 'Franchise-Partner werden',
        is_folder: false,
        parent_id: 322787213,
        published: true,
        path: null,
        position: -40,
        uuid: 'bb5c58f7-0b58-4365-b351-4020ae38a146',
        is_startpage: false,
        real_path: '/sunpoint/ueber-sunpoint/franchise-partner-werden',
      },
      {
        id: 322794043,
        slug: 'sunpoint/ueber-sunpoint/expansion-standort-fuer-sunpoint-anbieten',
        name: 'Expansion - Standort für SUNPOINT anbieten',
        is_folder: false,
        parent_id: 322787213,
        published: true,
        path: null,
        position: -30,
        uuid: 'e263065b-aefc-47fa-911d-f21431b4af20',
        is_startpage: false,
        real_path:
          '/sunpoint/ueber-sunpoint/expansion-standort-fuer-sunpoint-anbieten',
      },
      {
        id: 322792993,
        slug: 'sunpoint/ueber-sunpoint/sunpoint-beauty-tanning-studios',
        name: 'SUNPOINT Beauty- & Tanning-Studios',
        is_folder: false,
        parent_id: 322787213,
        published: true,
        path: null,
        position: -10,
        uuid: '97168026-551f-4107-9ae7-c017221b7936',
        is_startpage: false,
        real_path: '/sunpoint/ueber-sunpoint/sunpoint-beauty-tanning-studios',
      },
      {
        id: 322791673,
        slug: 'sunpoint/ueber-sunpoint/',
        name: 'Über SUNPOINT',
        is_folder: false,
        parent_id: 322787213,
        published: true,
        path: null,
        position: 0,
        uuid: 'c8434f0f-3059-4e11-a445-bbb126c1d46c',
        is_startpage: true,
        real_path: '/sunpoint/ueber-sunpoint/',
      },
    ],
  },
  {
    id: 322787215,
    slug: 'sunpoint/jobs',
    name: 'Jobs',
    is_folder: true,
    parent_id: 322563539,
    published: true,
    path: null,
    position: 80,
    uuid: 'b3c2e788-61ed-4215-a04c-f9222df5b52b',
    is_startpage: false,
    real_path: '/sunpoint/jobs',
    children: [
      {
        id: 325334103,
        slug: 'sunpoint/jobs/',
        name: 'Jobs',
        is_folder: false,
        parent_id: 322787215,
        published: true,
        path: null,
        position: -60,
        uuid: 'e8c991b7-c7f9-49dd-af80-6bb3c53967e1',
        is_startpage: true,
        real_path: '/sunpoint/jobs/',
      },
      {
        id: 382433939,
        slug: 'sunpoint/jobs/bewerbung-reinigungskraft',
        name: 'Bewerbung Reinigungskraft',
        is_folder: false,
        parent_id: 322787215,
        published: true,
        path: null,
        position: -50,
        uuid: '828ac39f-4bd3-4614-822e-e27cf0df8414',
        is_startpage: false,
        real_path: '/sunpoint/jobs/bewerbung-reinigungskraft',
      },
      {
        id: 382433545,
        slug: 'sunpoint/jobs/bewerbung-studioleiterin',
        name: 'Bewerbung StudioleiterIn',
        is_folder: false,
        parent_id: 322787215,
        published: true,
        path: null,
        position: -30,
        uuid: '27e6a060-6359-43c2-b010-89bab2fcfe5c',
        is_startpage: false,
        real_path: '/sunpoint/jobs/bewerbung-studioleiterin',
      },
      {
        id: 382423716,
        slug: 'sunpoint/jobs/bewerbung-studiomitarbeiterin',
        name: 'Bewerbung StudiomitarbeiterIn',
        is_folder: false,
        parent_id: 322787215,
        published: true,
        path: null,
        position: -10,
        uuid: '4e51e9ef-49d2-40e9-a2dc-55cae1a4051e',
        is_startpage: false,
        real_path: '/sunpoint/jobs/bewerbung-studiomitarbeiterin',
      },
    ],
  },
  {
    id: 322563539,
    slug: 'sunpoint',
    name: 'SUNPOINT',
    is_folder: true,
    parent_id: 0,
    published: true,
    path: null,
    position: 10,
    uuid: 'b78d0975-f1ac-4155-b3d6-11c9ee262ad8',
    is_startpage: false,
    real_path: '/sunpoint',
  },
]

export default HeaderNavigationSunpoint
